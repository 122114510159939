





















import Vue from 'vue';

export default Vue.extend({
  name: 'card',
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
});
