









































import Vue from 'vue';

export default Vue.extend({
  name: 'es-confirm-modal',

  props: {
    value: {
      required: true,
      type: Boolean,
    },
    cta: {
      default: 'Confirma',
      type: String,
    },
  },

  methods: {
    onCancel() {
      this.$emit('input', false);
    },

    onConfirm() {
      this.$emit('on-confirm');
      this.$emit('input', false);
    },
  },
});
