














/* eslint-disable */
import Vue from 'vue';

export default Vue.extend({
  name: 'es-calendly-modal',
})
