var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'es-skeleton__item': true
  }, _obj[("es-skeleton__item--" + _vm.color)] = true, _obj[("es-skeleton__item--" + _vm.ratio)] = _vm.ratio, _obj[("es-skeleton__item--" + _vm.shape)] = true, _obj ),style:({
    height: _vm.height,
    paddingBottom: _vm.localRatio,
    width: _vm.width,
  })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }